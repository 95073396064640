import { CountryUtil } from "@/core/utils/CountryUtil";
import { v4 as uuidv4 } from "uuid"
import { SpecificPageDataContainerBase } from "@/store/models/shared/SpecificPageDataContainerBase";

export class BankSpecificCountryDataContainer extends SpecificPageDataContainerBase {
    scoreSectionData: any;

    constructor(obj) {
        super(obj, true);

        this.scoreSectionData = {
            stats: {
                score: obj.stats.score || 0,
                importance: obj.stats.importance || 0,
                gap: obj.stats.gap || 0,
                scores: obj.stats.scores || 0,
                respondents: obj.stats.respondents || 0
            },
            country: {
                name: obj.stats.country,
                imagePath: CountryUtil.getCountryImage(obj.stats.country),
                uuid: uuidv4()
            }
        };
    }
}
