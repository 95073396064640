<template>
    <div class="bank-specific-country platform-dashboard-pages">
        <template v-if="!filterLoading">
            <router-link class="back-previous-screen" to="/bank/countries">
                {{ "< " + $t("Back to [Previous Screen]", { previousScreen: $t("Countries") }) }}
            </router-link>

            <el-select
                v-model="country"
                class="dedicated-field-state"
                filterable
                :disabled="!dedicatedCountryDataContainer"
                @change="handleCountryChange"
            >
                <el-option
                    v-for="(item, index) in bankCountries"
                    :key="index"
                    :label="item"
                    :value="item"
                />
            </el-select>

            <BankDedicatedCountryFilters
                ref="bankDedicatedCountryFilters"
                @apply-filters="applyFilters"
            />
        </template>

        <template v-if="!loading && !filterLoading && dedicatedCountryDataContainer">
            <div class="bank-specific-country__info">
                <div class="country-score">
                    <CountryScoreInfo
                        :scoreSectionData="dedicatedCountryDataContainer.scoreSectionData"
                    />
                </div>
                <div class="country-chart section-card">
                    <LineChart :chartData="dedicatedCountryDataContainer.dotChartSectionData" />
                </div>
            </div>

            <div class="bank-specific-country__bank-area">
                <div class="bank-product-area">
                    <DashboardCommonList
                        list-item-has-icon
                        hide-footer
                        hide-view-all
                        :filter-data="$refs.bankDedicatedCountryFilters.filterData"
                        :platform-type="PlatformTypeEnum.BANK"
                        :page-type="BankCommonListEnum.PRODUCT_AREA"
                        :dataContainer="dedicatedCountryDataContainer"
                        :score-data="dedicatedCountryDataContainer.productAreaSectionData"
                    />
                </div>
            </div>

            <div class="bank-specific-country__questions-list">
                <QuestionsTabsContainer
                    :benchmark-questions-list="dedicatedCountryDataContainer.questionsSectionData.questions"
                    :custom-questions="dedicatedCountryDataContainer.customQuestionsSectionData"
                    :is-loading="dedicatedCountryDataContainer.questionsSectionData.isLoading"
                    @onSortingChange="handleSortingChange"
                    @onClickQuestionItem="handleClickQuestionItem"
                    @onTabChange="questionActiveTab = $event"
                />

                <CustomPagination
                    v-if="dedicatedCountryDataContainer.questionsSectionData.pagination &&
                        questionActiveTab === QUESTIONS_TABS.BENCHMARK_QUESTIONS"
                    class="mt-2"
                    :is-loading="dedicatedCountryDataContainer.questionsSectionData.isLoading"
                    :current-page="dedicatedCountryDataContainer.questionsSectionData.page"
                    :total="dedicatedCountryDataContainer.questionsSectionData.pagination.totalItems"
                    :pageSize="dedicatedCountryDataContainer.questionsSectionData.limit"
                    @currentPageChange="handleCurrentPageChange"
                    @pageSizeChange="handlePageSizeChange"
                />
            </div>
        </template>

        <CustomLoader height="400px" v-else />
    </div>
</template>

<script>
import store from "@/store";
import { QUESTIONS_TABS } from "@/core/config/constants";
import { redirectToRouteWithName } from "@/buying-teams/helpers/UrlHelper";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { BankSpecificCountryDataContainer } from "@/store/models/bank/dashboard/BankSpecificCountryDataContainer";
import { BankCommonListEnum } from "@/store/enums/BankCommonListEnum";
import BankDedicatedCountryFilters from "@/buying-teams/pages/bank/dashboard/components/BankDedicatedCountryFilters";
import CustomLoader from "@/buying-teams/shared-components/utils/CustomLoader";
import CountryScoreInfo from "@/buying-teams/shared-components/dashboard/CountryScoreInfo";
import LineChart from "@/buying-teams/shared-components/charts/LineChart";
import DashboardCommonList from "@/buying-teams/shared-components/dashboard/DashboardCommonList";
import QuestionsTabsContainer from "@/buying-teams/shared-components/questions/QuestionsTabsContainer";
import CustomPagination from "@/buying-teams/shared-components/utils/CustomPagination";
import { PlatformTypeEnum } from "@/store/enums/PlatformTypeEnum";
import BankFiltersTypeEnum from "@/store/enums/bank/BankFiltersTypeEnum";
import { diagLog } from "@/core/helpers/GlobalHelper";

export default {
    name: "BankDedicatedCountry",
    components: {
        CustomPagination,
        QuestionsTabsContainer,
        DashboardCommonList,
        CountryScoreInfo,
        CustomLoader,
        BankDedicatedCountryFilters,
        LineChart
    },
    data() {
        return {
            loading: true,
            filterLoading: true,
            country: "",
            dedicatedCountryDataContainer: null,
            QUESTIONS_TABS,
            questionActiveTab: QUESTIONS_TABS.BENCHMARK_QUESTIONS,
            BankCommonListEnum,
            PlatformTypeEnum
        };
    },
    created() {
        setCurrentPageBreadcrumbs({
            title: this.bank.name,
            logo: this.bank.icon_path
        });
    },
    async mounted() {
        this.country = this.$route.params.country || "";
        if (this.country && this.country === '*') {
            let country = this.bankCountries[0] || '';
            if (!country) {
                await redirectToRouteWithName("dashboard-bank-countries");
            }
            this.country = country;
            this.handleCountryChange(this.country);
            return;
        }
        if (!this.country) {
            await redirectToRouteWithName("dashboard-bank-countries");
        }

        this.initPage();
    },
    methods: {
        async initPage() {
            await store.dispatch("initFiltersData", {
                type: BankFiltersTypeEnum.COUNTRY_OVERVIEW,
                payload: { country: this.country },
                needSave: false,
            });
            this.filterLoading = false;
            await this.fetchDashboardData(this.countryOverviewDashboardFilter);
        },
        applyFilters(filter) {
            this.fetchDashboardData(filter)
        },
        async fetchDashboardData(filters = null) {
            this.loading = true;
            await store.dispatch("fetchCountryOverview", {
                    country: this.country,
                    filter: filters
                })
                .then(res => {
                    this.dedicatedCountryDataContainer = new BankSpecificCountryDataContainer(res);
                    this.dedicatedCountryDataContainer.questionsSectionData.limit = 5;
                })
                .finally(() => {
                    this.loading = false;
                });

            this.fetchQuestionsData();
        },
        fetchQuestionsData() {
            this.dedicatedCountryDataContainer.questionsSectionData.isLoading = true;
            let payload = {
                page: this.dedicatedCountryDataContainer.questionsSectionData.page,
                limit: this.dedicatedCountryDataContainer.questionsSectionData.limit,
                order_key: this.dedicatedCountryDataContainer.questionsSectionData.orderKey,
                order_direction: this.dedicatedCountryDataContainer.questionsSectionData.orderDirection,
                filter: {
                    ...this.$refs.bankDedicatedCountryFilters.filterData,
                    countries: [this.country]
                },
                type: "sub_menu"
            };

            store.dispatch("fetchCountryOverviewQuestions", payload)
                .then(res => {
                    this.dedicatedCountryDataContainer.addQuestionsSectionData(res.questions, res.paginationMetadata);
                    this.dedicatedCountryDataContainer.customQuestionsSectionData.setCustomQuestionsAnalyticsData(res.customQuestions);
                    diagLog("CONTAINER ==>>>> ", this.dedicatedCountryDataContainer);
                })
                .finally(() => {
                    this.dedicatedCountryDataContainer.questionsSectionData.isLoading = false;
                });
        },
        handleCountryChange(e) {
            this.$router.replace({ name: "dashboard-bank-country", params: { country: e } });
            this.loading = true;
            this.filterLoading = true;
            this.initPage();
        },
        handleSortingChange(sorting) {
            this.dedicatedCountryDataContainer.questionsSectionData.page = 1;
            this.dedicatedCountryDataContainer.questionsSectionData.orderKey = sorting;
            this.dedicatedCountryDataContainer.addQuestionsSectionData([], null, true);
            this.fetchQuestionsData();
        },
        async handleClickQuestionItem(question, newTab) {
            this.dedicatedCountryDataContainer.customQuestionsSectionData.redirectLoading = question.id;

            const filters = await store.dispatch('singleQuestionRedirectFilterState', {
                questionId: question.id,
                filterData: {
                    ...this.countryOverviewDashboardFilter,
                    countries: [this.country]
                },
            });

            store.commit('SAVE_BANK_SINGLE_QUESTION_FILTER', filters);
            this.dedicatedCountryDataContainer.customQuestionsSectionData.redirectLoading = null;

            const questionUrl = `/bank/questions/${question.id}${question.isCustom ? '?isCustom=true' : ''}`;

            if (newTab) {
                window.open(questionUrl, '_blank');
            } else {
                this.$router.push(questionUrl);
            }
        },
        handleCurrentPageChange(page) {
            this.dedicatedCountryDataContainer.questionsSectionData.page = page;
            this.fetchQuestionsData();
        },
        handlePageSizeChange(size) {
            this.dedicatedCountryDataContainer.questionsSectionData.page = 1;
            this.dedicatedCountryDataContainer.questionsSectionData.limit = size;
            this.fetchQuestionsData();
        }
    },
    computed: {
        bank() {
            return store.getters.bank;
        },
        countryOverviewDashboardFilter() {
            return store.getters.countryOverviewDashboardFilter;
        },
        bankCountries() {
            return store.getters.getBankCountries;
        },
    },
    watch: {
        "$route.params": {
            handler() {
                const params = this.$route.params.country;
                if (params && params === '*' && this.country) {
                    this.$router.replace({ name: "dashboard-bank-country", params: { country: this.country } });
                }
            }
        }
    },
};
</script>

<style lang="scss">
.bank-specific-country {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;

    &__info {
        display: flex;
        gap: 12px;
        width: 100%;

        .country-score {
            width: 422px;
        }

        .country-chart {
            width: calc(100% - 434px);
            overflow: hidden;

            .line-chart {
                height: 100%;
            }
        }
    }

    .section-card {
        background: #FFFFFF;
        box-shadow: 0 9px 29px rgba(0, 0, 0, 0.06);
        border-radius: 17px;

        .section-card-header {
            height: 62px;
            padding: 10px 24px;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        .section-card-title {
            display: flex;
            align-items: center;
            gap: 8px;

            h2 {
                margin-bottom: 0;
                font-weight: 700;
                font-size: 18px;
                line-height: 13px;
                color: #000000;
                opacity: 0.87;
            }

            span {
                background: #ECECEC;
                border-radius: 3px;
                padding: 3px;
                font-weight: 600;
                font-size: 12px;
                line-height: 15px;
                color: #000000;
            }
        }

        .section-card-actions {
            display: flex;
            align-items: center;
            gap: 22px;

            > a {
                font-weight: 700;
                font-size: 16px;
                line-height: 13px;
                color: #435BF4;
                opacity: 0.87;

                &:hover {
                    opacity: 1;
                }
            }

            &__sort {
                width: 28px;
                height: 28px;
                background: transparent;
                padding: 0;
                border: none;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }

    &__bank-area {
        display: flex;
        gap: 12px;
        width: 100%;

        .bank-product-area {
            width: 100%;
        }
    }

    &__questions-list {
        width: 100%;
    }

}
</style>
